
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import apiEntity from "@/core/services/Entities";
import apiSettings from "@/core/services/Settings";
import campaignApi from "@/core/services/Campaign";

export default defineComponent({
    name: "attendance-monitoring",
    components: {},
    data() {
        return {
            entities: [],
            defaultExpandedKeys: [] as number[],
            defaultProps: {
                children: "companies",
                label: "name",
            },
            selectedYear: 0,
            selectableYears: [] as any,
            participationLink: "",
            loading: false,
            campaigns: [] as any,
        };
    },
    methods: {
        reloadData() {
            this.fetchEntities();
            this.fetchGroupReport();
        },
        handleNodeClick(data) {
            if (this.defaultExpandedKeys.indexOf(data.id) !== -1) {
                this.defaultExpandedKeys.splice(this.defaultExpandedKeys.indexOf(data.id), 1);
            } else {
                if (data && data.id) {
                    // Check if the node already has children loaded
                    this.fetchEntities(data.id);
                    this.defaultExpandedKeys.push(data.id);
                }
            }
        },
        updateTree(company_id, children) {
            const findAndUpdateNode = (nodes) => {
                return nodes.map((node) => {
                    if (node.id === company_id) {
                        // Return a new object with updated companies
                        return { ...node, companies: children };
                    } else if (node.companies && node.companies.length) {
                        // Recursively update child nodes
                        return { ...node, companies: findAndUpdateNode(node.companies) };
                    } else {
                        // Return the node as is if no updates are needed
                        return node;
                    }
                });
            };

            // Update the entities with the new structure
            this.entities = findAndUpdateNode(this.entities);
        },
        fetchEntities(company_id = null) {
            const params = { year: new Date().getFullYear(), company_id: null };
            if (company_id) {
                params.company_id = company_id;
            }
            apiEntity.getHierarchicalEntities(params).then((response) => {
                if (company_id) {
                    this.updateTree(company_id, response.data["hierarchical_companies"]);
                } else {
                    this.entities = response.data["hierarchical_companies"];
                }
            });
        },
        fetchGroupReport() {
            apiSettings.getGroupReport({ year: this.selectedYear }).then((response) => {
                if ("result" in response.data && "file" in response.data["result"]) {
                    this.participationLink = response.data["participation"]["link"];
                } else {
                    this.participationLink = "";
                }
            });
        },
        fetchCampaigns() {
            campaignApi.getCampaigns().then((response) => {
                this.campaigns = response.data["campaigns"];
                this.selectedYear = this.campaigns[0].id;
                this.fetchEntities();
                this.fetchGroupReport();
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.attendanceMonitoring.title"), []);
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.attendanceMonitoring.title"), []);
        },
    },
    created() {
        this.fetchCampaigns();
    },
});
