<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card pt-4 mb-xl-9">
                <div class="card-header border-0">
                    <div class="card-title">
                        <h2 class="fw-bolder">{{ $t("pages.attendanceMonitoring.title") }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="fw-bolder fs-2">
                        <div class="fs-7 fw-normal text-muted">
                            {{ $t("pages.attendanceMonitoring.desc") }}
                        </div>
                    </div>
                    <div class="p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded">
                        <div class="row">
                            <div class="col form-group">
                                <select v-model="selectedYear" class="form-control form-control-solid">
                                    <option v-for="campaign in campaigns" v-bind:key="campaign.id" :name="campaign.label" :value="campaign.id">{{ campaign.label }}</option>
                                </select>
                            </div>
                            <div class="col">
                                <button type="submit" class="btn btn-primary btn-light" @click="reloadData()">
                                    {{ $t("general.search") }}
                                </button>
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->
                    </div>
                </div>
            </div>
        </div>
        <template v-if="!loading">
            <div class="col-4 mb-4">
                <div class="card bg-primary bg-opacity-25 h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a href="#" class="fs-4 fw-bold text-hover-primary text-gray-600 m-0">{{ $t("pages.attendanceMonitoring.groupparticipation") }}</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8 flex-left">
                        <!-- <div class="d-flex align-items-center flex-wrap mb-5 fs-6">
<div class="text-success fw-bolder me-2"><span class="badge badge-light">Manager : Lucas Diderot</span></div>
</div> -->
                        <div class="d-flex align-items-left fw-bold">
                            <a v-if="this.participationLink" :href="this.participationLink" target="_blank" class="btn btn-primary btn-sm btn-light btn-active-light-primary">{{
                                $t("pages.attendanceMonitoring.accessAttendance")
                            }}</a>
                            <a v-else target="_blank" class="btn bg-black text-white btn-sm btn-active-light-primary disabled">
                                {{ $t("pages.attendanceMonitoring.notavailable") }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <template v-for="company in this.$store.getters.currentUser.user.userprofile.related_companies" v-bind:key="company.id">
                <div class="col-4 mb-4" v-if="company.participationlink">
                    <div class="card bg-primary bg-opacity-25 h-100">
                        <div class="card-header flex-nowrap border-0 pt-9">
                            <div class="card-title m-0">
                                <i class="fas fa-chart-line fs-3 me-3"></i>
                                <a href="#" class="fs-4 fw-bold text-hover-primary text-gray-600 m-0">{{ company.name }}</a>
                            </div>
                        </div>
                        <div class="card-body d-flex flex-column pb-8 flex-left">
                            <!-- <div class="d-flex align-items-center flex-wrap mb-5 fs-6">
<div class="text-success fw-bolder me-2"><span class="badge badge-light">Manager : Lucas Diderot</span></div>
</div> -->
                            <div class="d-flex align-items-left fw-bold">
                                <a
                                    v-if="company.participationlink"
                                    :href="company.participationlink"
                                    target="_blank"
                                    class="btn btn-primary btn-sm btn-light btn-active-light-primary"
                                    >{{ $t("pages.attendanceMonitoring.accessAttendance") }}</a
                                >
                                <a v-else target="_blank" class="btn bg-black text-white btn-sm btn-active-light-primary disabled"> Non disponible </a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="col-4 mb-4" v-if="this.$store.getters.currentUser.user.userprofile.participationlink">
                <div class="card bg-primary bg-opacity-25 h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a href="#" class="fs-4 fw-bold text-hover-primary text-gray-600 m-0">Suivi personnalisé</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8 flex-left">
                        <!-- <div class="d-flex align-items-center flex-wrap mb-5 fs-6">
<div class="text-success fw-bolder me-2"><span class="badge badge-light">Manager : Lucas Diderot</span></div>
</div> -->
                        <div class="d-flex align-items-center fw-bold">
                            <a :href="this.$store.getters.currentUser.user.userprofile.participationlink" class="btn btn-primary btn-sm btn-light btn-active-light-primary">{{
                                $t("pages.attendanceMonitoring.accessAttendance")
                            }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4">
                <div class="card h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0" href="#">{{ $t("pages.attendanceMonitoring.entitymonitoring") }}</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8">
                        <el-tree
                            :data="entities"
                            :default-expanded-keys="defaultExpandedKeys"
                            node-key="id"
                            ref="tree"
                            highlight-current
                            :props="defaultProps"
                            @node-expand="handleNodeClick"
                            @node-collapse="handleNodeClick"
                        >
                            <template #default="{ node, data }">
                                <span class="custom-tree-node">
                                    <span>{{ node.label }} </span>
                                    <span>
                                        <template v-if="data.externalcompanyparticipations.length > 0">
                                            <a :href="data.externalcompanyparticipations[0].link" target="_blank"> Accéder à la participations </a>
                                        </template>
                                        <template v-else> Participation non disponible </template>
                                    </span>
                                </span>
                            </template>
                        </el-tree>
                    </div>
                </div>
            </div>
        </template>
        <div v-else>
            <div class="d-flex row-auto flex-center w-100 h-200px">
                <span class="spinner-border text-primary" role="status"></span>
            </div>
        </div>
    </div>
</template>
<style>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
</style>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import apiEntity from "@/core/services/Entities";
import apiSettings from "@/core/services/Settings";
import campaignApi from "@/core/services/Campaign";

export default defineComponent({
    name: "attendance-monitoring",
    components: {},
    data() {
        return {
            entities: [],
            defaultExpandedKeys: [] as number[],
            defaultProps: {
                children: "companies",
                label: "name",
            },
            selectedYear: 0,
            selectableYears: [] as any,
            participationLink: "",
            loading: false,
            campaigns: [] as any,
        };
    },
    methods: {
        reloadData() {
            this.fetchEntities();
            this.fetchGroupReport();
        },
        handleNodeClick(data) {
            if (this.defaultExpandedKeys.indexOf(data.id) !== -1) {
                this.defaultExpandedKeys.splice(this.defaultExpandedKeys.indexOf(data.id), 1);
            } else {
                if (data && data.id) {
                    // Check if the node already has children loaded
                    this.fetchEntities(data.id);
                    this.defaultExpandedKeys.push(data.id);
                }
            }
        },
        updateTree(company_id, children) {
            const findAndUpdateNode = (nodes) => {
                return nodes.map((node) => {
                    if (node.id === company_id) {
                        // Return a new object with updated companies
                        return { ...node, companies: children };
                    } else if (node.companies && node.companies.length) {
                        // Recursively update child nodes
                        return { ...node, companies: findAndUpdateNode(node.companies) };
                    } else {
                        // Return the node as is if no updates are needed
                        return node;
                    }
                });
            };

            // Update the entities with the new structure
            this.entities = findAndUpdateNode(this.entities);
        },
        fetchEntities(company_id = null) {
            const params = { year: new Date().getFullYear(), company_id: null };
            if (company_id) {
                params.company_id = company_id;
            }
            apiEntity.getHierarchicalEntities(params).then((response) => {
                if (company_id) {
                    this.updateTree(company_id, response.data["hierarchical_companies"]);
                } else {
                    this.entities = response.data["hierarchical_companies"];
                }
            });
        },
        fetchGroupReport() {
            apiSettings.getGroupReport({ year: this.selectedYear }).then((response) => {
                if ("result" in response.data && "file" in response.data["result"]) {
                    this.participationLink = response.data["participation"]["link"];
                } else {
                    this.participationLink = "";
                }
            });
        },
        fetchCampaigns() {
            campaignApi.getCampaigns().then((response) => {
                this.campaigns = response.data["campaigns"];
                this.selectedYear = this.campaigns[0].id;
                this.fetchEntities();
                this.fetchGroupReport();
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.attendanceMonitoring.title"), []);
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.attendanceMonitoring.title"), []);
        },
    },
    created() {
        this.fetchCampaigns();
    },
});
</script>
