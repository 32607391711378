import ApiService from "@/core/services/ApiService";

const ADD_CAMPAIGN = "result/add-campaign/";
const GET_CAMPAIGNS = "result/get-campaigns";
const UPDATE_CAMPAIGN = "result/update-campaign/<pk>/";

export default {
    addCampaign(campaign) {
        ApiService.setHeader();
        return ApiService.post(ADD_CAMPAIGN, campaign);
    },
    updateCampaign(campaign_id, campaign) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_CAMPAIGN.replace("<pk>", campaign_id), campaign);
    },
    getCampaigns() {
        ApiService.setHeader();
        return ApiService.get(GET_CAMPAIGNS).then((response) => {
            return response.data;
        });
    },
};
